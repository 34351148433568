<template lang="pug">
.datasets-page.pa-10
    div.mt-10
        h2 Archive Recommended 
        v-data-table.mt-6(
            v-model="selectedDatasets",
            :items="recommendedDatasetsToArchive",
            :headers="headers",
            show-select,
            item-key="id",
            items-per-page=25,
            :sort-by.sync="sortBy",
            :sort-desc.sync="sortDesc"
            :loading="loading",
            :footer-props="{'items-per-page-options': [10, 25, 50, 100]}",
            dense,
            no-data-text="No datasets need to be archived"
            loading-text="Loading Datasets... Please wait"
        )
            template(v-slot:footer.page-text)
                v-btn(v-on:click="archiveDatasets") Archive
    div.mt-10
        h2 No Actions Needed
        v-data-table.mt-6(
            v-model="selectedDatasets"
            :items="noActionNeeded",
            :headers="headers",
            show-select,
            item-key="id",
            :loading="loading",
            no-data-text="Nothing to See Here"
            loading-text="Loading Datasets... Please wait"
        )
</template>

<script>
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { baseUrl } from '@/utils/serviceLayer'
import prettyBytes from 'pretty-bytes';
import axios from 'axios'
export default {
    data: () => {
        return {
            sortBy: 'createdAt',
            sortDesc: false,
            loading: true,
            recommendedDatasetsToArchive: [],
            noActionNeeded: [],
            selectedDatasets: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Name', value: 'name' },
                { text: 'Created At', value: 'createdAt' },
                { text: 'Actively Stored Bytes', value: 'bytes' },
            ],
        }
    },
    props: {
        baseUrl: {
            type: String,
            default: baseUrl
        },
        token: {
            type: String
        }
    },
    mounted() {
        NioOpenApiModule.initCallback(this.openApiInit)
    },
    methods: {
        async openApiInit(token) {
            this.token = token;
            this.getDatasets().then(() => {
                this.loading = false;
            });
        },
        async archiveDatasets() {
            const selectedDatasets = this.selectedDatasets.map(dataset => dataset.id);
            const response = await axios.delete(`${this.baseUrl}/datasets`, {
                data: {
                    ids: selectedDatasets
                },
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            });
            this.getDatasets().then(() => {
                this.loading = false;
                return Promise.resolve(response);
            });
        },
        // Get the datasets and project them to a simpler form
        async getDatasets() {
            this.loading = true;
            this.recommendedDatasetsToArchive = [];
            this.noActionNeeded = [];
            this.selectedDatasets = [];

            const resp = await axios.get(`${baseUrl}/datasets`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            const allDatasets = resp.data.records.map(dataset => {
                return {
                    id: dataset.id,
                    name: dataset.name,
                    description: dataset.description,
                    createdAt: new Date(dataset.createdAt).toISOString().split('T')[0],
                    updatedAt: dataset.updatedAt,
                    bytes: dataset.stats.records && dataset.stats.records.length > 0 ? prettyBytes(dataset.stats.records[0].activeDatasetStoredBytes) : prettyBytes(0),
                    activeDatasetStoredBytes: dataset.stats.records && dataset.stats.records.length > 0 ? dataset.stats.records[0].activeDatasetStoredBytes : 0
                }
            })

            allDatasets.forEach(dataset => dataset.activeDatasetStoredBytes == 0
                ? this.recommendedDatasetsToArchive.push(dataset)
                : this.noActionNeeded.push(dataset));

            return Promise.resolve();
        }
    }
};
</script>